$(document).ready(function() {
	$(".select-sort").minimalect({
		placeholder: "Sắp xếp theo"
	});



	$(".banner-slide").slick({
		speed: 600,
		autoplay: true,
		autoplaySpeed: 4000,
		swipeToSlide: true,
		touchThreshold: 10,
		dots: true
	});

	var elm = document.querySelector(".nav-wrapper ul");
	var ms = new MenuSpy(elm, {
		threshold: 100
	});

	$(".inter-result li").click(function() {
		var map = $(this).attr("data-map");
		$(".map-inter").attr("src", map);
		$(this).addClass("active");
		$(this)
			.siblings()
			.removeClass("active");
	});

	$(".inter-result li:first-child").trigger("click");
});



$(window).on('load', function() {
 // code here
 $(".product-thumbnail").slick({
	slidesToShow: 3,
	vertical: true,
	verticalSwiping: true,
	infinite: false,
	asNavFor: ".product-imgs",
	focusOnSelect: true,
	prevArrow: $(".slide-prev"),
	nextArrow: $(".slide-next"),
	responsive: [
		{
			breakpoint: 576,
			settings: {
				vertical: false,
				verticalSwiping: false
			}
		}
	]
});

$(".product-imgs").slick({
	speed: 500,
	fade: true,
	cssEase: "linear",
	infinite: false,
	asNavFor: ".product-thumbnail"
});
});
var offset;

if (window.innerWidth < 1025) {
	offset = 64;
} else {
	offset = 80;
}

UIkit.sticky(".nav-wrapper", {
	offset: offset
});

const menuMobileMapping = new MappingListener({
	selector: ".menu-wrapper",
	mobileWrapper: ".mobile-menu",
	mobileMethod: "appendTo",
	desktopWrapper: ".language-wrapper",
	desktopMethod: "insertBefore",
	breakpoint: 1025
}).watch();
